import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { signInWithLink, verifyUser } from "../../firebase/auth";

import logo from "../../logo.png";

import "./index.css";

type Content = {
  title: string;
  body: string;
};

export const HandleMagicLink = () => {
  const [content, setContent] = useState<Content>();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const mode = searchParams.get("mode");
  const apiKey = searchParams.get("apiKey");
  const oobCode = searchParams.get("oobCode");
  const continueUrl = searchParams.get("continueUrl");

  useEffect(() => {
    const signIn = async () => {
      if (!(apiKey && oobCode && mode)) {
        setContent({
          title: "Oops!",
          body: "Your link is invalid. Please request another sign in link and try again.",
        });
        return;
      }

      try {
        switch (mode) {
          case "verifyEmail": {
            // Add minimum wait time of 1 second to avoid flashing the loading screen
            await Promise.all([
              verifyUser(oobCode),
              new Promise((res) => {
                setTimeout(res, 1000);
              }),
            ]);

            setContent({
              title: "Your email's been verified!",
              body: "You'll now start receiving the Scaturday Digest newsletter with updates on your favourite scat models. If you're not redirected in 5 seconds, click the button below.",
            });

            setTimeout(() => {
              if (continueUrl) {
                window.location.href = continueUrl;
              }
            }, 5000);

            break;
          }
          case "signIn": {
            const email = window.localStorage.getItem("email");

            if (!email) {
              throw new Error("");
            }

            // Add minimum wait time of 1 second to avoid flashing the loading screen
            await Promise.all([
              signInWithLink(email, window.location.href),
              new Promise((res) => {
                setTimeout(res, 1000);
              }),
            ]);

            if (continueUrl) {
              window.location.href = continueUrl;
            } else {
              navigate("/", {
                replace: true,
              });
            }
            break;
          }
        }
      } catch (error) {
        console.log(error);

        setContent({
          title: "Oops!",
          body: "Something went wrong. You may need to request another log-in link. If the issue persists, contact us at info@scatfans.com.",
        });
      }
    };

    signIn();
  }, [navigate, searchParams, apiKey, oobCode, mode, continueUrl]);

  const goToSignIn = () => {
    if (continueUrl) {
      window.location.href = continueUrl;
    } else {
      navigate("/", {
        replace: true,
      });
    }
  };

  return (
    <div className="flex items-center justify-center min-h-full px-4 py-12 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-6">
        <div>
          <img className="w-auto h-12 mx-auto" src={logo} alt="KinkyScat.com" />
          {content && (
            <h2 className="mt-6 text-3xl font-bold tracking-tight text-center text-gray-900">
              {content.title}
            </h2>
          )}
        </div>

        <div className="space-y-6 text-center">
          {!content ? (
            <svg
              className="w-10 h-10 mx-auto text-indigo-500 animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            <>
              <p>{content.body}</p>
              <button
                type="submit"
                onClick={goToSignIn}
                className="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed"
              >
                Continue
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default HandleMagicLink;
