import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { sendMagicLink } from "../../endpoints";
import { getUser } from "../../firebase/auth";

import logo from "../../logo.png";

import "./index.css";

export const Login = () => {
    const navigate = useNavigate();

    const [userEmail, setUserEmail] = useState<string>(undefined!);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchUser = async () => {
            const [user] = await Promise.all([
                getUser(),
                new Promise((res) => {
                    setTimeout(res, 1000);
                }),
            ]);

            if (user) {
                navigate("/");
            }

            setLoading(false);
        };

        fetchUser();
    }, [navigate]);

    const location = useLocation();

    const f = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email("This email isn't valid.")
                .required("Please enter an email address."),
        }),
        onSubmit: async ({ email }, { setErrors }) => {
            try {
                window.localStorage.setItem("email", email);
            } catch (error) {
                setErrors({
                    email: "Something went wrong while logging you in. Please try again.",
                });
            }

            const from = `${window.origin}${
                location.state?.from?.pathname || "/"
            }`;

            const rawResponse = await sendMagicLink(email, from);
            const response = await rawResponse.json();

            if (!rawResponse.ok) {
                setErrors({
                    email: response.message,
                });
            } else {
                setUserEmail(email);
            }
        },
    });

    return (
        <div className='flex items-center justify-center min-h-full px-4 py-12 sm:px-6 lg:px-8'>
            <div className='w-full max-w-md space-y-8'>
                <div>
                    <img
                        className='w-auto h-12 mx-auto'
                        src={logo}
                        alt='KinkyScat.com'
                    />
                    {!loading && (
                        <h2 className='mt-6 text-3xl font-bold tracking-tight text-center text-gray-900'>
                            {userEmail
                                ? "Check your email!"
                                : "Manage your Scaturday Digest"}
                        </h2>
                    )}
                </div>

                {loading ? (
                    <svg
                        className='w-10 h-10 mx-auto text-indigo-500 animate-spin'
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'>
                        <circle
                            className='opacity-25'
                            cx='12'
                            cy='12'
                            r='10'
                            stroke='currentColor'
                            strokeWidth='4'></circle>
                        <path
                            className='opacity-75'
                            fill='currentColor'
                            d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'></path>
                    </svg>
                ) : (
                    <>
                        {userEmail ? (
                            <div className='mt-8 space-y-6'>
                                <p className='text-center'>
                                    We've sent an email to{" "}
                                    <span className='font-bold'>
                                        {userEmail}
                                    </span>
                                    . Click the link to confirm your sign in and
                                    manage your Scaturday Digest mailing
                                    preferences.
                                </p>
                            </div>
                        ) : (
                            <form
                                className='mt-8 space-y-6'
                                onSubmit={f.handleSubmit}>
                                <div className='rounded-md'>
                                    <div className='shadow-sm'>
                                        <label
                                            htmlFor='email-address'
                                            className='sr-only'>
                                            Email address
                                        </label>
                                        <input
                                            id='email'
                                            name='email'
                                            autoComplete='email'
                                            className='relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                                            placeholder='Email address'
                                            value={f.values.email}
                                            onChange={f.handleChange}
                                        />
                                    </div>
                                    {f.touched.email && f.errors.email ? (
                                        <p className='mt-2 text-red-500 sm:text-sm'>
                                            {f.errors.email}
                                        </p>
                                    ) : null}
                                </div>

                                <div>
                                    <button
                                        type='submit'
                                        disabled={f.isSubmitting}
                                        className='relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed'>
                                        {f.isSubmitting
                                            ? "Signing in..."
                                            : "Sign in"}
                                    </button>
                                </div>
                            </form>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default Login;
