import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  getAllLists,
  getSubscribedLists,
  setSubscribedLists,
} from "../../endpoints";

import logo from "../../logo.png";

import "./index.css";

type NewsletterList = {
  id: string;
  name: string;
};

type NewsletterListResponse = {
  lists: NewsletterList[];
};

type ListState = (NewsletterList & {
  subscribed: boolean;
})[];

export const ManagePreferences = () => {
  const [lists, setLists] = useState<ListState>([]);
  const [error, setError] = useState<string>();

  useEffect(() => {
    const fetchLists = async () => {
      const [allListsResponse, userListsResponse] = await Promise.all([
        getAllLists(),
        getSubscribedLists(),
        new Promise((res) => {
          setTimeout(res, 1000);
        }),
      ]);

      if (!allListsResponse.ok || !userListsResponse.ok) {
        setError(
          "Something went wrong while loading your Scaturday Digest preferences. Please try again later."
        );
        return;
      }

      const { lists: allLists } =
        (await allListsResponse.json()) as NewsletterListResponse;
      const { lists: userLists } =
        (await userListsResponse.json()) as NewsletterListResponse;

      const userListIds = userLists.map((list) => list.id);

      const listState = allLists.map((l) => ({
        ...l,
        subscribed: userListIds.includes(l.id),
      }));

      setLists(listState);
    };

    fetchLists();
  }, []);

  const f = useFormik({
    initialValues: Object.fromEntries(lists.map((l) => [l.id, l.subscribed])),
    onSubmit: async (values) => {
      const listIds = Object.entries(values)
        .filter((v) => v[1] !== false)
        .map(([id]) => id);

      const setListResponse = await setSubscribedLists(listIds);

      if (!setListResponse.ok) {
        setError(
          "Something went wrong while setting your Scaturday Digest preferences. Please try again later."
        );
        return;
      }
    },
    enableReinitialize: true,
  });

  const loading = lists.length === 0;

  return (
    <div className="flex items-center justify-center min-h-full px-4 py-12 sm:px-6 lg:px-8">
      <div className="w-full max-w-3xl space-y-8">
        <div>
          <img className="w-auto h-12 mx-auto" src={logo} alt="KinkyScat.com" />
          {!loading && (
            <h2 className="mt-6 text-3xl font-bold tracking-tight text-center text-gray-900">
              Manage your <br /> Scaturday Digest preferences
            </h2>
          )}
        </div>

        {loading ? (
          <svg
            className="w-10 h-10 mx-auto text-indigo-500 animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        ) : (
          <>
            <form className="mt-8 space-y-6" onSubmit={f.handleSubmit}>
              <legend className="sr-only">Newsletter Lists</legend>

              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                {Object.entries(f.values).map((v) => (
                  <div className="relative flex items-center">
                    <div className="flex items-center h-5">
                      <input
                        id={v[0]}
                        name={v[0]}
                        checked={v[1]}
                        onChange={f.handleChange}
                        type="checkbox"
                        className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor={v[0]}
                        className="font-medium text-gray-700"
                      >
                        {lists.find((l) => l.id === v[0])!.name}
                      </label>
                    </div>
                  </div>
                ))}
              </div>

              <div className="space-y-3">
                <button
                  type="submit"
                  disabled={f.isSubmitting}
                  className="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed"
                >
                  {f.isSubmitting ? "Saving changes..." : "Save changes"}
                </button>
                <Link
                  to="/unsubscribe"
                  className="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-indigo-700 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Unsubscribe
                </Link>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default ManagePreferences;
