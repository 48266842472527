import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RequireAuth from "./components/RequireAuth";

import Login from "./routes/login";
import HandleMagicLink from "./routes/magic";
import ManagePreferences from "./routes/manage";
import Unsubscribe from "./routes/unsubscribe";

function App() {
    const router = createBrowserRouter([
        {
            path: "/",
            element: (
                <RequireAuth>
                    <ManagePreferences />
                </RequireAuth>
            ),
        },
        {
            path: "/login",
            element: <Login />,
        },
        {
            path: "/unsubscribe",
            element: (
                <RequireAuth>
                    <Unsubscribe />
                </RequireAuth>
            ),
        },
        {
            path: "/magic",
            element: <HandleMagicLink />,
        },
    ]);

    return <RouterProvider router={router} />;
}

export default App;
