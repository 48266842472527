import { FC, useState, useEffect } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { getUser } from "../firebase/auth";

const RequireAuth: FC<{ children: JSX.Element }> = ({ children }) => {
    const [isUser, setIsUser] = useState<boolean>(null!);

    useEffect(() => {
        const fetchUser = async () => {
            const user = await getUser();

            setIsUser(user !== null);
        };

        fetchUser();
    }, []);

    const location = useLocation();

    if (isUser === null) {
        return null;
    }

    return isUser ? (
        children
    ) : (
        <Navigate to='/login' state={{ from: location }} replace />
    );
};

export default RequireAuth;
