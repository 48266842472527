import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyDGZVo55JfEHP9eOtSFn1zyMQYGvx_-NKw",
    authDomain: "ks-3f279.firebaseapp.com",
    databaseURL: "https://ks-www-default-rtdb.firebaseio.com",
    projectId: "ks-www",
    storageBucket: "ks-www.appspot.com",
    messagingSenderId: "956072083777",
    appId: "1:956072083777:web:4efae34e6710d66ea9a1c0",
    measurementId: "G-T3E90FGQLC",
};

const app = initializeApp(firebaseConfig);

export default app;
