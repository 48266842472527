import { getAuth } from "firebase/auth";

const generateIdToken = () => getAuth().currentUser?.getIdToken();

const BASE_ENDPOINT = "https://us-central1-ks-www.cloudfunctions.net";

export const sendMagicLink = async (email: string, from = "/") =>
  fetch(`${BASE_ENDPOINT}/sendMagicLink`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      from,
    }),
  });

export const unsubscribeUser = async () =>
  fetch(`${BASE_ENDPOINT}/unsubscribeUser`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: (await generateIdToken()) as string,
    },
  });

export const getAllLists = async () =>
  fetch(`${BASE_ENDPOINT}/getAllLists`, {
    method: "GET",
    headers: {
      Authorization: (await generateIdToken()) as string,
    },
  });

export const getSubscribedLists = async () =>
  fetch(`${BASE_ENDPOINT}/getSubscribedLists`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: (await generateIdToken()) as string,
    },
  });

export const setSubscribedLists = async (listIds: string[]) =>
  fetch(`${BASE_ENDPOINT}/setSubscribedLists`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: (await generateIdToken()) as string,
    },
    body: JSON.stringify({
      listIds,
    }),
  });
