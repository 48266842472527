import {
    applyActionCode,
    getAuth,
    isSignInWithEmailLink,
    signInWithEmailLink,
    User,
    setPersistence,
    browserSessionPersistence,
} from "firebase/auth";

import app from ".";

export const signInWithLink = async (
    email: string,
    url: string
): Promise<User> => {
    const auth = getAuth(app);

    if (!isSignInWithEmailLink(auth, url)) {
        throw new Error("Invalid URL");
    }

    await setPersistence(auth, browserSessionPersistence);
    const { user } = await signInWithEmailLink(auth, email, url);

    return user;
};

export const getUser = async (): Promise<User | null> => {
    const auth = getAuth(app);

    const currentUser: User | null = await new Promise((res) => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            unsubscribe();
            res(user);
        });
    });

    return currentUser;
};

export const verifyUser = async (oobCode: string): Promise<void> => {
    const auth = getAuth(app);

    return applyActionCode(auth, oobCode);
};
