import { User } from "firebase/auth";
import { useEffect, useState } from "react";
import { unsubscribeUser } from "../../endpoints";
import { getUser } from "../../firebase/auth";

import logo from "../../logo.png";

import "./index.css";

export const Unsubscribe = () => {
  const [userProfile, setUserProfile] = useState<User>(undefined!);

  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const [unsubscribed, setUnsubscribed] = useState<boolean>(false);

  const [error, setError] = useState<string>();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        // Add minimum wait time of 1 second to avoid flashing the loading screen
        const [user] = await Promise.all([
          getUser() as Promise<User>,
          new Promise((res) => {
            setTimeout(res, 1000);
          }),
        ]);

        setUserProfile(user);
      } catch (error) {
        const { message } = error as Error;

        setError(
          `Something went wrong while loading your Scaturday Digest preferences. Please try again later. (${message})`
        );
      }
    };

    fetchUserProfile();
  }, []);

  const handleUnsubscribe = async () => {
    setSubmitting(true);

    try {
      const [rawResponse] = await Promise.all([
        unsubscribeUser(),
        new Promise((res) => {
          setTimeout(res, 2500);
        }),
      ]);

      const response = await rawResponse.json();

      if (!rawResponse.ok) {
        setError(response.message);
      }

      setUnsubscribed(true);
    } catch (error) {
      const { message } = error as Error;

      setError(
        `Something went wrong while unsubscribing you from the Scaturday Digest. Please try again later. (${message})`
      );
    }
  };

  return (
    <div className="flex items-center justify-center min-h-full px-4 py-12 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-6">
        <div>
          <img className="w-auto h-12 mx-auto" src={logo} alt="KinkyScat.com" />
          {userProfile && (
            <h2 className="mt-6 text-3xl font-bold tracking-tight text-center text-gray-900">
              {error ? (
                "Oops!"
              ) : (
                <>
                  Unsubscribe from <br />
                  the Scaturday Digest
                </>
              )}
            </h2>
          )}
        </div>

        <div className="space-y-6 text-center">
          {!userProfile ? (
            <svg
              className="w-10 h-10 mx-auto text-indigo-500 animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : error ? (
            <p>{error}</p>
          ) : (
            <>
              {!unsubscribed && (
                <>
                  <p>
                    We're sorry you're unsubscribing from the Scaturday Digest.
                    Click the button below to stop receiving your personalised
                    scat newsletter at{" "}
                    <span className="font-bold">{userProfile.email}</span>.
                  </p>

                  <button
                    onClick={handleUnsubscribe}
                    disabled={isSubmitting}
                    className="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed"
                  >
                    {isSubmitting ? "Unsubscribing..." : "Unsubscribe"}
                  </button>
                </>
              )}

              {unsubscribed && (
                <p>
                  You've now been unsubscribed from the Scaturday Digest and
                  should no longer any receive emails from us. If you'd ever
                  like to come back, subscribe to the latest models on{" "}
                  <a
                    className="text-indigo-500 underline hover:text-indigo-600"
                    href="https://kinkyscat.com"
                  >
                    KinkyScat.com
                  </a>
                  .
                </p>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Unsubscribe;
